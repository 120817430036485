import httpService from "@/request"

// 商品
// 供应商
export function vendorList(params) {
	return httpService({
		url: `/user/shopVendor/list`,
		method: 'get',
		params: params,
	})
}
export function vendorInsert(params) {
	return httpService({
		url: `/user/shopVendor/insert`,
		method: 'post',
		data: params,
	})
}
export function vendorDel(params) {
	return httpService({
		url: `/user/shopVendor/delete`,
		method: 'post',
		data: params,
	})
}
export function vendorUpdate(params) {
	return httpService({
		url: `/user/shopVendor/update`,
		method: 'post',
		data: params,
	})
}
